import React, { useEffect, useState } from 'react';
import forge from 'node-forge';
import { Row, Col, Button, Form, Alert, Image, Container } from 'react-bootstrap';

const wsUrl = process.env.REACT_APP_API_PCLINKER.replace('https', 'wss').replace('http', 'ws');
let socket;

const Conected = (props) => {
  const [imageSrc, setImageSrc] = useState('');
  const [connectionStabished, setConnectionStabished] = useState(false)

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    console.log("isHovered", isHovered);
  }, [isHovered])

  const [roomId, setRoomId] = useState("room1");

  const password = "password1"

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseMove = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    console.log(e.nativeEvent);

    socket.send(
      JSON.stringify({
        type: 'message',
        room: roomId,
        action: 'move',
        x: offsetX,
        y: offsetY
      })
    );
  };

  const handleConnect = () => {
    let pingInterval
    socket = new WebSocket(wsUrl);

    pingInterval = setInterval(() => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(
          JSON.stringify({
            type: 'ping'
          })
        );
      }
    }, 20000);

    socket.onopen = () => {
      setConnectionStabished(true)
      socket.send(
        JSON.stringify({
          type: 'join',
          room: roomId,
          password: password,
          content: { name: 'Alan' },
        }));
      handleLoadData()
    };

    socket.onmessage = (event) => {
      const data = event.data;
      let json_msg = JSON.parse(data);

      console.log("onmessage", json_msg);

      if (json_msg?.content?.img) {
        const md = forge.md.sha256.create();
        md.update(json_msg.content.img);
        setImageSrc(`data:image/png;base64,${json_msg.content.img}`);
      }
      return
    };

    socket.onclose = () => {
      clearInterval(pingInterval)
    };
  };

  const handleLoadData = () => {
    socket.send(
      JSON.stringify({
        type: 'message',
        room: roomId,
        content: {
          command: 'load_data',
          parameters: {}
        },
      })
    );
    socket.send(
      JSON.stringify({
        type: 'connect',
        room: roomId
      })
    );
  }

  const handleImageClick = (event) => {
    if (!connectionStabished) {
      return
    }
    console.log("handleImageClick");

    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    socket.send(JSON.stringify({
      type: 'message',
      room: roomId,
      action: 'click',
      x: x,
      y: y
    }));
  };

  useEffect(() => {
    const handleKeyPress = (event) => {

      if (!connectionStabished || !isHovered) {
        return;
      }

      console.log("handleKeyPress");

      const key = event.key;

      socket.send(JSON.stringify({
        type: 'message',
        room: roomId,
        action: 'keypress',
        key: key
      }));
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [connectionStabished, socket]);

  const handleSendConnect = () => {
    console.log("message", "room", roomId);
    socket.send(
      JSON.stringify({
        type: 'message',
        room: roomId,
        action: 'connect'
      })
    );
  }

  useEffect(() => {
    const interval1 = setInterval(() => {
      if (!socket) {
        return
      }
      console.log('connect');
      socket.send(
        JSON.stringify({
          type: 'message',
          action: 'connect',
          room: roomId
        })
      );
    }, 10000);
    return () => { clearInterval(interval1) };
  }, [])

  const handleDisconnect = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          'type': 'desconnect',
          'room': roomId
        })
      );
      socket.close();
      setConnectionStabished(false);
    }
  };

  useEffect(() => {
    handleConnect();
    return () => {
      if (socket) socket.close();
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          {connectionStabished ?
            <Alert variant="success" className='mt-2 mb-2' style={{ padding: 6 }}>
              Conectado
            </Alert>
            :
            <Alert variant="danger" className='mt-2 mb-2' style={{ padding: 6 }}>
              Desconectado
            </Alert>
          }
        </Col>

        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
          <Form.Control
            className="mt-2 mb-2"
            placeholder="ID do parceiro"
            value={roomId}
            onChange={(e) => setRoomId(e.target.value)}
          />
        </Col>

        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
          <Button
            className='mt-2 mb-2 w-100'
            disabled={false}
            variant='success'
            onClick={() => { handleSendConnect() }}
          >
            Conectar
          </Button>
        </Col>
      </Row >

      <Row className='text-center'>
        <Col className="p1 mt-2 mb-2 d-flex justify-content-center">
          <div
            style={{
              width: '1400px',
              height: '800px',
              border: '8px solid',
              borderRadius: '5px',
              borderImage: 'linear-gradient(to right, black, gray)',
              borderImageSlice: 1,
              padding: '10px',
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
            }}
          >
            <div>
              {!imageSrc ? (
                <span style={{ color: 'black' }}>
                  Aguardando imagem...
                </span>
              ) : (
                <Image
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onMouseMove={handleMouseMove}
                  onClick={handleImageClick}
                  src={imageSrc}
                  style={{
                    width: '1366px',
                    height: '768px',
                    objectFit: 'cover',
                    //cursor: connectionStabished ? 'pointer' : 'none',
                    opacity: connectionStabished ? 1 : 0.5,
                    filter: connectionStabished ? 'none' : 'grayscale(100%)',
                  }}
                />
              )}
            </div>

          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Conected;
