import { Container } from "react-bootstrap";
import Conected from "../conected/conected";

function Home() {
  return (
    <div>
      <Container fluid>

        <Conected />
      </Container>
    </div>
  );
}

export default Home;
