import React, { useState } from "react";
import { Form, Button, Container, Row, Col, FloatingLabel } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../services/Api"

const Register = () => {

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    if (!email | !password) {
      setError('Preencha o campo E-mail e Senha!');
      return;
    } else {
      handleSignup()
    }
  }

  const handleSignup = async () => {
    try {

      var { data } = await Api.post("/api/v1/create-user", {
        name: name,
        email: email,
        password: password
      })
      console.log(data);
      localStorage.setItem('user', JSON.stringify(data));
      alert("Usuário criado com sucesso!")
      navigate("/login");

    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        alert(error?.response?.data?.message)
      } else {
        alert("Erro ao criar usuário!")
      }
    }
  };

  const handleRegister = () => {
    // Verifica se as senhas coincidem
    if (password !== confirmPassword) {
      setError('As senhas não coincidem');
      return;
    }
    // Limpa o erro e continua com o login
    setError('');
    handleLogin();
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h2 className="text-center my-4">Registrar</h2>

          {/* Floating Label for Name */}
          <FloatingLabel controlId="formBasicName" label="Nome" className="mb-3">
            <Form.Control
              type="text"
              placeholder="Digite seu nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FloatingLabel>

          {/* Floating Label for Email */}
          <FloatingLabel controlId="formBasicEmail" label="Email" className="mb-3">
            <Form.Control
              type="email"
              placeholder="Digite seu email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FloatingLabel>

          {/* Floating Label for Password */}
          <FloatingLabel controlId="formBasicPassword" label="Senha" className="mb-3">
            <Form.Control
              type="password"
              placeholder="Digite sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FloatingLabel>

          {/* Floating Label for Confirm Password */}
          <FloatingLabel controlId="formConfirmPassword" label="Confirme sua senha">
            <Form.Control
              type="password"
              placeholder="Confirme sua senha"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </FloatingLabel>



          <Button
            variant="primary"
            className="mt-3 w-100"
            size="lg"
            onClick={() => {
              handleRegister()
            }}
            block
          >
            Registrar
          </Button>

          {error && <p className="msg-error">{error}</p>}
          <div className="mt-3 text-center">
            <p>
              Já tem uma conta? <Link to="/login">Faça login aqui</Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
