import React, { useState, useEffect } from "react";
import "./login.css"
import { Form, Button, Container, Row, Col, FloatingLabel } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../services/Api";

const Login = () => {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');
  const [isLoggedIn, setisLoggedIn] = useState(false);

  const handleLogin = async () => {
    if (!email || !password) {
      setError("Preencha E-mai e Password!")
      return
    }
    try {
      var { data } = await Api.post("/api/v1/login", {
        email: email,
        password: password
      })
      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('token', JSON.stringify(data.token));
      setisLoggedIn(true)
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        setError(error?.response?.data?.message)
      } else {
        setError("Erro ao acessar!")
      }
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
      localStorage.clear()
    } else {
      navigate("/");
    }
  }, [navigate, isLoggedIn])

  return (
    <Container fluid className="vh-100 d-flex p-0">
      <Row className="w-100 m-0">
        {/* Imagem à esquerda, ocupando 7/12 da tela */}
        <Col md={7} className="p-0">
          <div style={{ height: '100vh', overflow: 'hidden' }}>
            <img
              src="./img/img_login.jpg" // Substitua pela URL da sua imagem
              alt="Login"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
        </Col>

        <Col md={5} className="d-flex align-items-center justify-content-center p-4" style={{background: "#f4f4f4"}}>

          <div className="div-login">
            <h2 className="text-center my-4">Login</h2>

            <FloatingLabel controlId="floatingInput" label="E-mail" className="mb-3">
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail"
                required
              />
            </FloatingLabel>

            <FloatingLabel controlId="floatingPassword" label="Password">
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
            </FloatingLabel>

            <Button
              variant="primary"
              type="submit"
              className="mt-3 w-100"
              size="lg"
              onClick={() => {
                handleLogin()
              }}
            >
              Entrar
            </Button>

            {error && <p className="msg-error">{error}</p>}
            <div className="mt-3 text-center">
              <p>
                Não tem uma conta? <Link to="/register">Cadastre-se aqui</Link>
              </p>
            </div>
          </div>

        </Col>
      </Row>
    </Container>
  );
};

export default Login;
